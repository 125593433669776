@import "./typography";

$primary: #000;
$secondray: #fff;
$green: #00e2b6;
$darkGreen: #255957;

img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  @media screen and (max-width: 500px) {
    padding-left: 16px;
  }
}

div.header-desktop {
  min-height: 130px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 2px solid $secondray;
  a:not(.menu-item-button) {
    color: $green;
    padding: 10px 20px;
    margin-right: 15px;

    &.active:not(.logo-link) {
      font-weight: 700;
      border: 1px solid $green;
    }
  }
  span.last {
    flex-grow: 1;
  }
}

h2 {
  color: $secondray;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 3rem;
  @media screen and (max-width: 1130px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

#blog svg.MuiSvgIcon-root {
  font-size: 6rem;
  color: $primary;
}

button.MuiButton-root,
div.MuiOutlinedInput-root {
  border-radius: 0;
  height: 40px;
}

button.slick-prev,
button.slick-next {
  width: auto;
  height: auto;
  @media (max-width: 2449px) and (min-width: 959.95px) {
    top: 60%;
  }
  @media screen and (min-width: 2450px) {
    top: 65%;
  }
  &::before {
    font-size: 4rem;
    color: $darkGreen;
    width: 40px;
    height: 40px;
  }
}

div.just-filterd .first_sli {
  button.slick-prev,
  button.slick-next {
    &::before {
      color: $green;
    }
  }
}

button.slick-next {
  right: 0;
  z-index: 999;
  @media (min-width: 478px) and (max-width: 991px) {
    right: 0px;
  }
}

button.slick-prev {
  left: 0px;
  z-index: 999;
}

div.slick-track > div {
  position: relative;
}

.slick-slide img {
  max-width: 2400px;
  margin: 0 auto;
}

img.logo {
  width: 225px;
  margin-right: 45px;
  flex-grow: 1;
  &-mobile {
    width: 150px;
    margin-right: 15px;
    padding: 15px;
    padding-left: 22px;
  }
}

.header-mobile {
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 1131px) {
    display: none;
  }
}

div#menuButton {
  margin-right: 16px;
  color: $secondray;
  position: relative;
  text-align: center;
  outline: none;
  img {
    width: 90%;
    max-width: 100%;
    border: 3px solid transparent;
    &:hover {
      transition: all 0.2s ease-in-out;
      border: 3px solid $secondray;
    }
  }
  h3 {
    position: absolute;
    bottom: 15%;
    left: 0;
    right: 0;
  }
  svg {
    position: relative;
    bottom: 30px;
    visibility: hidden;
    font-size: 2rem;
    left: 0;
    color: $primary;
    right: 0;
  }
  &:hover {
    outline: none;
    h3 {
      color: $green;
    }
    svg {
      color: $green;
      visibility: visible;
    }
  }
  &.not_active {
    outline: none;
    img {
      position: relative;
      border: 0;
    }
    &:hover {
      transform: none;
      h3 {
        color: $secondray;
      }
      svg {
        visibility: hidden;
      }
    }
  }
  &.is_active {
    outline: none;
    img {
      position: relative;
      border: 0;
      border: 3px solid $secondray;
    }
    h3 {
      color: $green;
    }
    &::after {
      content: "";
      position: absolute;
      left: 42%;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 30px solid $secondray;
      clear: both;
    }
    &:hover {
      transform: none;

      svg {
        visibility: hidden;
      }
    }
  }
}

.car-title {
  text-align: center;
}

.login-buttons {
  a {
    &:hover {
      text-decoration: none;
    }
    button.register-button {
      background-color: transparent;
      color: $green;
      border-color: $green;
      margin-right: 15px;
      &:hover,
      &:focus {
        background-color: $green;
        color: $secondray;
        border-color: $green;
      }
    }
    button.login-button {
      background-color: $green;
      color: $secondray;
      border-color: $green;
      margin-right: 15px;
      &:hover,
      &:focus {
        background-color: transparent;
        color: $green;
        border-color: $green;
      }
    }
  }
}

.course_desc {
  color: $secondray;
}

div.course_desc {
  display: none;
  &:nth-of-type(1) {
    display: block;
    padding: 30px;
    padding-top: 0;
  }
}

div.filter-cards div.slick-track {
  margin-left: 30px;
  margin-right: 30px;
  @media screen and (max-width: 550px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.footer_grid {
  border-top: 2px solid $secondray;
  background: linear-gradient(
    90deg,
    $darkGreen 0%,
    #437c90 54%,
    $darkGreen 100%
  ) !important;
  img {
    width: 225px;
    @media screen and (max-width: 478px) {
      width: 200px;
    }
    padding-top: 30px;
    padding-left: 30px;
  }
  a.trigger-link {
    color: $secondray;
    text-decoration: none;
  }
  div {
    text-align: left;
    padding-top: 16px;
    padding-bottom: 16px;
    h1 {
      margin-top: 85px;
      color: $secondray;
      margin-bottom: 20px;
      @media screen and (max-width: 1279px) {
        margin-top: 30px;
        padding-left: 30px;
      }
      @media screen and (max-width: 478px) {
        margin-top: 0px;
      }
    }
    p {
      color: $secondray;
      margin-bottom: 20px;
      padding-right: 100px;
      @media screen and (max-width: 1279px) {
        padding-left: 30px;
        padding-right: 70px;
      }
      @media screen and (max-width: 1000px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    a.twitter {
      &:hover {
        svg {
          color: #00acee;
        }
      }

      svg {
        @media screen and (max-width: 1279px) {
          padding-left: 30px;
        }
      }
    }
    a.linkedin {
      &:hover {
        svg {
          color: #0e76a8;
        }
      }
    }
    svg {
      color: $secondray;
      margin-right: 5px;
    }
  }
}

a.menu_link {
  color: $darkGreen;
}

div.header-desktop {
  .login-buttons {
    display: flex;
    a {
      color: $secondray;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @media screen and (max-width: 1130px) {
    display: none;
  }
}

.header-mobile {
  display: flex;
  justify-content: space-between;
  a {
    margin-right: 15px;
  }
  @media screen and (min-width: 1131px) {
    display: none;
  }
  svg {
    color: $secondray;
  }
}

button.register-button {
  background-color: transparent;
  color: $green;
  border-color: $green;
  margin-right: 15px;
  &:hover,
  &:focus {
    background-color: $green;
    color: $secondray;
    border-color: $green;
  }
}

button.login-button {
  background-color: $green;
  color: $secondray;
  border-color: $green;
  margin-right: 15px;
  &:hover,
  &:focus {
    background-color: transparent;
    color: $green;
    border-color: $green;
  }
}

.banner-wrapper {
  position: relative;
  img {
    width: 100%;
    margin-top: 50px;
    @media screen and (max-width: 478px) {
      margin-top: 65px;
    }
  }

  p {
    font-family: "SpectralSemiBold", serif;
    color: $secondray;
    position: absolute;
    left: 15%;
    right: 15%;
    top: 25%;
    font-size: 5rem;
    text-align: center;
    @media screen and (max-width: 1700px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 1400px) {
      top: 35%;
      font-size: 3rem;
    }

    @media screen and (max-width: 1130px) {
      top: 20%;
      font-size: 2.5rem;
    }

    @media screen and (max-width: 767px) {
      top: 15%;
      font-size: 2rem;
    }
    @media screen and (max-width: 600px) {
      position: static;
      top: auto;
      font-size: 2rem;
      padding: 15px;
      margin-top: -4px;
      margin-bottom: 0;
      border-bottom: 3px solid $green;
      border-top: 3px solid $green;
    }
  }
}

span.course_length {
  color: $secondray;
}

div#menu-appbar {
  .MuiPopover-paper {
    top: 85px !important;
  }
}

p.text {
  font-size: 1.4rem;
  color: $green;
}
p.user-name {
  padding-left: 15px;
  margin-top: 24px;
}

div.Blog-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  h2 {
    color: $green;
    padding-bottom: 0;
    padding-top: 0;
  }

  @media screen and (max-width: 500px) {
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;
    margin-top: -20px;

    h2 {
      padding-top: 50px;
    }
  }

  a {
    text-decoration: none;
  }
  button {
    padding: 16px 20px;
    margin-bottom: 20px;
    &:hover,
    &:focus {
      border: 1px solid $green;
    }
  }
}

svg.close_button {
  float: right;
  color: $secondray;
  font-size: 40px;
  margin-right: 20px;
  cursor: pointer;
}

span.course_length {
  padding-left: 30px;
}

div.user_area {
  display: flex;
}

.loader-wrapper {
  position: relative;
  margin: 20px;
  .loader {
    left: calc(50% - 20px);
    position: absolute;
    color: $green;
  }
}

button.demo-button {
  @extend .login-button;
  margin-right: 0;
}

.contact-form {
  margin-top: 135px;
  @media screen and (max-width: 1130px) {
    margin-top: 67px;
  }
}

.contact-form__container {
  border: 1px solid #f5f5f5;
  padding: 35px;
  max-width: 600px;
  margin: 30px auto;
  background-color: $secondray;
}
.contact-form__container p {
  margin: 0;
  text-align: left;
}
.contact-form__header h2 {
  color: #4a4a4a;
}
.contact-form__header p {
  margin-bottom: 0;
  color: #4a4a4a;
}
.contact-form__header {
  margin-left: -8px;
  margin-right: -8px;
  padding: 2px 35px 31px;

  background-color: $green;
  h2,
  p {
    text-align: center;
    font-weight: 500;
    color: $secondray;
  }
}
input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;

  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: $green;
  color: $secondray;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  border: 1px solid $green;
  font-size: bold;
}
textarea {
  height: 140px;
}
input[type="submit"]:hover {
  background-color: $secondray;
  color: $green;
  border: 1px solid $green;
}

.container {
  background-color: #f2f2f2;
  padding: 20px;
}
.sucsess {
  margin: 20px 0;
  background-color: $green;
  color: $secondray;
  text-align: center;
  padding: 12px 20px;
  font-weight: bold;
}

.warning {
  margin-bottom: 10px;
  display: block;
  background-color: red;
  color: $secondray;
  text-align: center;
  padding: 12px 20px;
  font-weight: bold;
}

.who-link {
  text-decoration: none;
}

.app-container {
  max-width: 1280px;
  position: relative;
  display: block;
  margin: 0 auto;
  margin-top: 130px;
  margin-bottom: -3px;

  @media (min-width: 768px) and (max-width: 1439px) {
    max-width: 1024px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 100px;
  }

  ul.slick-dots {
    bottom: 0px;
    button {
      width: 50px;
      height: 50px;
    }
    li button:before {
      font-size: 16px;
    }
  }
}

.content {
  padding-bottom: 100px;
  background-color: $secondray;
  > div {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 40px;

    @media (min-width: 768px) and (max-width: 1439px) {
      max-width: 1024px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
    }
  }
  ul {
    padding: 0;
    margin-left: 20px;
  }
  h1 {
    color: $green;
  }
}

ul.slick-dots li button:before {
  color: $secondray;
}

ul.slick-dots li.slick-active button:before {
  color: $secondray;
}

div.home-page,
div.who-we-are {
  background-color: $primary;
  margin-left: -8px;
  margin-right: -8px;
}

.filter-result {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $darkGreen;
}

.who-we-are {
  h2 {
    color: $green;
  }
}

.logos-container {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}

.logos-container div {
  -webkit-animation: scroll 40s linear infinite;
  -moz-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: 100%;
  img {
    height: 100px;
    padding-right: 30px;
    padding-left: 30px;
    max-width: 100%;
    &.no-padding {
      padding: 0;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

/*=== scroll Animation ===*/
@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}
@-moz-keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.logos-wrapper {
  background-color: $secondray;
  border-top: 1px solid $primary;
  h2 {
    color: $green;
    padding-bottom: 0;
  }
}

button.btn {
  bottom: 20px;
  right: 20px;
  position: fixed;
  transition: transform 0.5s ease 0s;
  background-color: $green;
  box-shadow: rgba(0, 250, 154, 0.15) 0px 12px 24px 0px;

  &:hover {
    background-color: $green;
  }
  svg {
    fill: $secondray;
  }
}
.btn:hover {
  opacity: 0.9;
}
.btn:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.img {
  height: 200px;
  width: 300px;
}
a.rsc-float-button {
  background-color: $green;
}
div.rsc-container {
  border-radius: 0;
  @media screen and (max-width: 991px) {
    z-index: 9999;
  }
  @media screen and (max-height: 700px) {
    border-radius: 0;
    bottom: 0 !important;
    left: initial !important;
    height: 100%;
    right: 0 !important;
    top: initial !important;
    width: 100%;
    z-index: 9999;
  }
  .rsc-content {
    @media screen and (max-height: 700px) {
      height: calc(100% - 112px);
    }
  }
}

.external-link a {
  color: $green;
}
.player-wrapper {
  overflow: hidden;
  position: relative;
  width: 50%;

  &-iframe {
    background-color: $secondray;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;
    padding-right: 0px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &::after {
      padding-top: 56.25%;
      display: block;
      content: "";
    }
  }
  video {
    filter: grayscale(1);
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.video-wrapper {
  background-color: $green;
  padding-top: 50px;
  padding-bottom: 50px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }

  h2 {
    color: $secondray;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.video-text {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 12px 24px 0px;
  text-align: left;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 20px !important;
  margin-top: 20px !important;
  background-color: $secondray;

  @media screen and (max-width: 958px) {
    margin: 20px !important;
  }

  h3 {
    color: $primary;
    margin-top: 0;
    text-align: left;
    margin-bottom: 0;
  }
  p {
    color: $primary;
    text-align: left;
  }
}

.video-section {
  max-width: 100%;
  background-color: $secondray;
  position: relative;
  video {
    max-width: 100%;
    padding-bottom: 50px;
  }
}
.newslist,
.news-item {
  margin-top: 160px;
  margin-bottom: 50px;
  .title,
  h1.title {
    text-align: left;
    color: $green;
  }
  .image-news-page {
    width: 500px;
    height: auto;
  }
  .news-card {
    padding: 20px;
    border: 1px solid #f2f2f2;
  }
  .card {
    margin-bottom: 20px;
  }
  h4.news-title {
    margin-top: 0;
    margin-bottom: 0;
    a {
      color: $green;
    }
  }
  .read-more {
    background-color: $green;
    color: $secondray;
    border-color: $green;
    margin-right: 15px;
    border-radius: 0;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $green;
      border-color: $green;
    }
  }

  #load-more {
    background-color: $green;
    color: $secondray;
    text-align: center;
    padding: 10px 30px;
    border: 1px solid $green;
    margin-right: 15px;
    border-radius: 0;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $green;
      border-color: $green;
    }
    &:disabled {
      background: #dddddd;
      border-color: #dddddd;
      pointer-events: none;
      &:hover,
      &:focus {
        background-color: #dddddd;
        color: $secondray;
        border-color: $secondray;
      }
    }
  }

  .other-news {
    background-color: $green;
    color: $secondray;
    margin-top: 15px;
    text-align: center;
    padding: 10px 30px;
    border: 1px solid $green;
    margin-right: 15px;
    border-radius: 0;
    &:hover,
    &:focus {
      background-color: transparent;
      color: $green;
      border-color: $green;
    }
  }
  .news-body {
    padding-left: 15px;
    @media screen and (max-width: 585px) {
      padding-top: 15px;
      padding-left: 0px;
    }
  }
  .published-item {
    display: block;
  }
}

button.scrollTop {
  position: fixed;
  left: 32px;
  bottom: 32px;
  align-items: center;
  background-color: $green;
  color: $secondray;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.8;
  &:hover {
    background-color: $green;
    color: $secondray;
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

.news-item {
  h2 {
    color: $primary;
    margin-top: 0;
    margin-bottom: 10px;
  }
  a {
    color: $green;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .description {
    margin-top: 10px;
    display: block;
    color: #292828;
  }
}

.footer_grid{
  .contact p {
    margin-top: 0;
    margin-bottom: 0;

    a{
      color: $secondray;
      &:hover{
        font-weight: 700;
      }
    }
  }

}

.slick-slider{
  margin-bottom: 30px;
}