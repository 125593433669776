@import "./fonts";

$primary: #000;


html,
body {
  font-family: "KarlaRegular", sans-serif;
  font-size: 1rem;
}

h1 {
  font-size: 3rem;
  font-family: "SpectralSemiBold", serif;
  line-height: 1;
  @media screen and (max-width: 478px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2.5rem;
  line-height: 1;
  font-family: "SpectralSemiBold";
  @media screen and (max-width: 478px) {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.75rem;
  line-height: 1;
  font-family: "SpectralSemiBold";
  @media screen and (max-width: 478px) {
    font-size: 1.25rem;
  }
}

h4 {
  font-size: 1.5rem;
  line-height: 1;
  font-family: "SpectralSemiBold";
  @media screen and (max-width: 478px) {
    font-size: 1.125rem;
  }
}

h5 {
  font-size: 1rem;
  line-height: 1;
  font-family: "SpectralSemiBold";
  @media screen and (max-width: 478px) {
    font-size: 1rem;
  }
}

button.MuiButton-root,
a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary {
  font-family: "KarlaRegular";
}

p,
input {
  font-family: "KarlaRegular", sans-serif;
  line-height: 1.5;
}
p.org_name {
  font-family: "KarlaBold", sans-serif;
  font-size: 1.2rem;
}
