@font-face {
    font-family: 'SpectralSemiBold';
    src: local('Spectral-SemiBold'), url(../fonts/Spectral-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'KarlaRegular';
    src: local('KarlaRegular'), url(../fonts/Karla-Regular.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'KarlaBold';
    src: local('KarlaBold'), url(../fonts/Karla-Bold.ttf) format('truetype');
  }
  